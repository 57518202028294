import { useEffect, useRef, useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import CookieConsent, { TRACKING_ID } from "../src/components/CookieConsent/CookieConsent";
import { Button, ButtonProps } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesUp } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import Spinner from "react-bootstrap/Spinner";
import ApiGuide from "./pages/docs/api-types";

// ProgressBar Component
const ProgressBar = styled.div`
  position: fixed;
  top: 85px; // Adjust based on your layout
  left: 0;
  width: 0;
  height: 5px;
  background-color: rgba(0, 161, 206, 0.877);
  z-index: 9999;
  /* transition: width 0.25s ease-out; */
  @media only screen and (max-width: 991px) {
    top: 70px; // navbar height mobile
  }
`;

const Layout = styled.div`
  background-size: cover;
  background-position: center;
  box-shadow: 0px 0px 0vw calc(200vw) inset rgba(249, 252, 255, 0.95);
  font-family: "Quicksand", sans-serif;
  background-attachment: fixed;
  min-height: calc(100vh - 80px);
  padding-top: 80px; // navbar height desktop
  max-width: 100vw;

  @media only screen and (max-width: 991px) {
    padding-top: 70px; // navbar height mobile
  }
`;

const ArticleWrapper = styled.div`
  min-height: calc(100vh - 80px);
  background-color: #ffffffc7;
  /* box-shadow: 0 0 200vw 200vw #ffffff9f; */
  width: 100vw;
  background-attachment: fixed;
  margin: 0 auto;
  position: relative;

  @media only screen and (max-width: 991px) {
    background-attachment: initial;
    padding: 0px;
  }
  @media only screen and (min-width: 1600px) {
    width: 90vw;
  }
  @media only screen and (min-width: 1800px) {
    width: 75vw;
  }
  @media only screen and (min-width: 2000px) {
    width: 70vw;
  }
  @media only screen and (min-width: 2200px) {
    width: 65vw;
  }
  @media only screen and (min-width: 2400px) {
    width: 60vw;
  }
  @media only screen and (min-width: 2600px) {
    width: 55vw;
  }
  @media only screen and (min-width: 2800px) {
    width: 50vw;
  }
  @media only screen and (min-width: 3200px) {
    width: 45vw;
  }
  @media only screen and (min-width: 3600px) {
    width: 40vw;
  }
  @media only screen and (min-width: 4000px) {
    width: 35vw;
  }
`;

const DivOuterPage = styled.div`
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0);
  background-position: 100% 30%;
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const useProgressiveImage = (src: string) => {
  const [sourceLoaded, setSourceLoaded] = useState("");

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setSourceLoaded(src);
  }, [src]);

  return sourceLoaded;
};

const StyledButtonToTop = styled(Button)<ButtonProps>`
  font-size: 20px;
  z-index: 1111111;
  background: #212529;
  position: fixed;
  bottom: 25px;
  right: 5.5vw;
  border-radius: 0;
  color: #858585;
  transition: opacity ease 1s;
`;

// Lazy load components
const Home = lazy(() => import("./pages/home"));
const TermsOfService = lazy(() => import("./pages/terms-of-service"));
const PrivacyPolicy = lazy(() => import("./pages/privacy-policy"));
const Imprint = lazy(() => import("./pages/imprint"));
const Cookies = lazy(() => import("./pages/cookies"));
const Http404 = lazy(() => import("./components/Http404/404"));
const LabWebsiteDeployment = lazy(() => import("./pages/labs/ci-cd-website-deployment"));
const CurlGuide = lazy(() => import("./pages/docs/curl-guide"));
const GoGuide = lazy(() => import("./pages/docs/go-guide"));
const TypeScriptGuide = lazy(() => import("./pages/docs/typescript-guide"));
const PythonGuide = lazy(() => import("./pages/docs/python-guide"));
const ReactGuide = lazy(() => import("./pages/docs/react-guide"));
const ReactMigrationGuide = lazy(() => import("./pages/docs/react-to-nextjs-migration-guide"));
const ReactaAndTypescriptCourse = lazy(() => import("./pages/labs/react-and-typescript-course"));
const IaCWithAWSCDKGuide = lazy(() => import("./pages/labs/aws-cdk-first-steps"));

function App() {
  const source =
    "https://images.pexels.com/photos/7146291/pexels-photo-7146291.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  const loaded = useProgressiveImage(source);
  const goToTopRef = useRef<null | HTMLDivElement>(null);
  const goToTopButtonRef = useRef<null | HTMLButtonElement>(null);

  const [offset, setOffset] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const checkCookieSettings = () => {
      const cookieSettings = localStorage.getItem("cookieConsent");

      if (cookieSettings?.includes("advertising")) {
        console.log("Info: Google Analytics activated");
        ReactGA.initialize(TRACKING_ID);
        ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
      }
    };

    // Check the cookie settings initially
    checkCookieSettings();

    // Set up an event listener to check cookie settings when localStorage changes
    const handleStorageChange = () => {
      checkCookieSettings();
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (goToTopRef.current) {
      goToTopRef.current.scrollIntoView({
        behavior: "auto",
        block: "end"
      });
    }
    const onScroll = () => {
      setOffset(window.pageYOffset);

      const winScroll = document.documentElement.scrollTop || document.body.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      setProgress(scrolled);
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const upIcon = <FontAwesomeIcon icon={faAnglesUp} />;

  function goToTop() {
    if (goToTopRef.current) {
      goToTopRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end"
      });
    }
  }

  return (
    <Router>
      <DivOuterPage
        style={{
          backgroundImage: `url(${loaded})`
        }}
        className="d-flex flex-column min-vh-100"
      >
        <div ref={goToTopRef}></div>
        <Navbar />
        <Layout>
          <ProgressBar style={{ width: `${progress}%` }} />
          {offset > 200 ? (
            <StyledButtonToTop
              ref={goToTopButtonRef}
              style={{ opacity: "1" }}
              variant="outline-secondary"
              onClick={() => goToTop()}
            >
              {upIcon}
            </StyledButtonToTop>
          ) : (
            <StyledButtonToTop
              ref={goToTopButtonRef}
              style={{ opacity: "0", cursor: "unset" }}
              variant="outline-secondary"
              onClick={() => goToTop()}
            >
              {upIcon}
            </StyledButtonToTop>
          )}
          <CookieConsent />
          <Suspense fallback={<StyledSpinner animation="grow" variant="dark" />}>
            <Routes>
              <Route
                path="/docs/react-to-nextjs-guide"
                element={
                  <ArticleWrapper>
                    <ReactMigrationGuide />
                  </ArticleWrapper>
                }
              />
              <Route
                path="/docs/curl-guide"
                element={
                  <ArticleWrapper>
                    <CurlGuide />
                  </ArticleWrapper>
                }
              />
              <Route
                path="/lab/react-and-typescript"
                element={
                  <ArticleWrapper>
                    <ReactaAndTypescriptCourse />
                  </ArticleWrapper>
                }
              />
              <Route
                path="/lab/aws-cdk-first-steps"
                element={
                  <ArticleWrapper>
                    <IaCWithAWSCDKGuide />
                  </ArticleWrapper>
                }
              />
              <Route
                path="/docs/react-guide"
                element={
                  <ArticleWrapper>
                    <ReactGuide />
                  </ArticleWrapper>
                }
              />
              <Route
                path="/docs/python-guide"
                element={
                  <ArticleWrapper>
                    <PythonGuide />
                  </ArticleWrapper>
                }
              />
              <Route
                path="/docs/go-guide"
                element={
                  <ArticleWrapper>
                    <GoGuide />
                  </ArticleWrapper>
                }
              />
              <Route
                path="/docs/typescript-guide"
                element={
                  <ArticleWrapper>
                    <TypeScriptGuide />
                  </ArticleWrapper>
                }
              />{" "}
              <Route
                path="/docs/api-types"
                element={
                  <ArticleWrapper>
                    <ApiGuide />
                  </ArticleWrapper>
                }
              />
              <Route
                path="/aws/labs/ci-cd-website-deployment"
                element={
                  <ArticleWrapper>
                    <LabWebsiteDeployment />
                  </ArticleWrapper>
                }
              />
              <Route
                path="/cookies"
                element={
                  <ArticleWrapper>
                    <Cookies />
                  </ArticleWrapper>
                }
              />
              <Route
                path="/terms-of-service"
                element={
                  <ArticleWrapper>
                    <TermsOfService />
                  </ArticleWrapper>
                }
              />
              <Route
                path="/privacy-policy"
                element={
                  <ArticleWrapper>
                    <PrivacyPolicy />
                  </ArticleWrapper>
                }
              />
              <Route
                path="/imprint"
                element={
                  <ArticleWrapper>
                    <Imprint />
                  </ArticleWrapper>
                }
              />
              <Route path="/" element={<Home />} />
              <Route path="*" element={<Http404 />} />
            </Routes>
          </Suspense>
        </Layout>
        <Footer />
      </DivOuterPage>
    </Router>
  );
}

export default App;
