import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Pages } from "../pages/pages";

const StyledDivResultItem = styled.div`
  transition: background-color ease-out 0.333s;
  background-color: #212529;
  color: #858585;
  padding: 7px;
  &:hover {
    background-color: #343d47;
    color: white;
    cursor: pointer;
  }
`;

function ResultList(props: {
  input: string;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  searchResultsRef: React.RefObject<HTMLDivElement>;
}) {
  //create a new array by filtering the original array
  const filteredData = Pages.filter((el) => {
    //if no input the return the original
    if (props.input === "") {
      return el;
    }
    //return the item which contains the user input in headline or searchValues
    else {
      const lowerCaseInput = props.input.toLowerCase();
      return (
        el.headline.toLowerCase().includes(lowerCaseInput) ||
        el.searchValues.some((value) => value.toLowerCase().includes(lowerCaseInput))
      );
    }
  });

  function removeSearchResults() {
    if (props.searchResultsRef.current !== null) {
      props.searchResultsRef.current.style.display = "none";
      props.inputRef.current.value = "";
    }
  }

  return (
    <div className="result" key={props.input}>
      {filteredData.map((item, index) => (
        <Link key={index} style={{ textDecoration: "none" }} to={item.url} onClick={removeSearchResults}>
          <StyledDivResultItem key={index}>{item.headline}</StyledDivResultItem>
        </Link>
      ))}
    </div>
  );
}

export default ResultList;
