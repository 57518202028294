import { faAnglesRight, faLink, IconName, IconPrefix } from "@fortawesome/free-solid-svg-icons";
import { faReact, faGolang, faPython, faNode } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const listIcon = <FontAwesomeIcon icon={faAnglesRight} />;
export const reactIcon = (
  <FontAwesomeIcon icon={faReact} className="dropdown-item-icon" style={{ alignSelf: "center" }} />
);
export const CardLinkIcon = <FontAwesomeIcon icon={faLink} color="#bfbfbf" size="xs" />;
export const goIcon = (
  <FontAwesomeIcon icon={faGolang} className="dropdown-item-icon" style={{ alignSelf: "center" }} />
);
export const pythonIcon = (
  <FontAwesomeIcon icon={faPython} className="dropdown-item-icon" style={{ alignSelf: "center" }} />
);
export const nodeIcon = (
  <FontAwesomeIcon icon={faNode} className="dropdown-item-icon" style={{ alignSelf: "center" }} />
);

export const githubIcon = <FontAwesomeIcon icon={["fab" as IconPrefix, "github" as IconName]} />;
export const ninjaIcon = <FontAwesomeIcon icon={["fa" as IconPrefix, "user-ninja" as IconName]} />;

export const awsIcon = (
  <FontAwesomeIcon
    className="dropdown-item-icon"
    style={{ alignSelf: "center" }}
    icon={["fab" as IconPrefix, "aws" as IconName]}
  />
);

export const bookIcon = (
  <FontAwesomeIcon
    className="dropdown-item-icon"
    style={{ alignSelf: "center" }}
    icon={["fa" as IconPrefix, "book" as IconName]}
  />
);

export const StyledH1 = styled.h1`
  padding-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  line-height: 1.5;
`;

export const StyledDescription = styled.h2`
  line-height: 1.5;
  font-size: 16px;
  border-left: 5px solid #00a1ce94;
  padding: 5px 5px 5px 20px;
  margin-left: 30px;
  margin-right: 30px;
`;

export const StyledSpanArticleTime = styled.div`
  border-top: 1px solid #39393928;
  border-bottom: 1px solid #39393928;
  padding: 10px 0;
  font-size: 0.9rem;
  font-weight: 400;
  padding-left: 30px;
  padding-right: 30px;
`;

export const StyledTD = styled.td`
  font-size: 16px;
  display: flex;
  background-color: rgba(0, 0, 0, 0) !important;
  &:hover {
    cursor: pointer;
  }
  div.content-item {
    /* padding-left: 15px; */
    /* width: calc(100% - 30px); */
    background-color: rgba(0, 0, 0, 0);
  }
`;

export const StyledDivText = styled.div`
  margin: 10px 30px 5px 30px;
  font-size: 1rem;
  line-height: 2;
  padding: 15px 0;
  text-align: justify;

  p.source {
    text-decoration: none;
    padding-left: 10px;
    font-size: 0.9rem;
    margin-bottom: 0;
    padding-bottom: 5px;
  }
`;

export const StyledSingleLineOfCode = styled.span`
  font-family: "Courier New", monospace; /* Monospace-Schriftart für Code */
  background-color: #f4f4f4; /* Hintergrundfarbe des Code-Containers */
  padding: 2px; /* Innenabstand für den Code-Container */
  margin: 0 5px; /* Außenabstand oberhalb und unterhalb des Code-Containers */
  border: 1px solid #ccc; /* Rahmen um den Code-Container */
  white-space: pre-wrap; /* Behandlung von Leerzeichen und Zeilenumbrüchen */
  overflow-x: auto; /* Horizontales Scrollen aktivieren, wenn der Code breiter ist als der Container */
`;

export const StyledPreconditions = styled.h3`
  border-top: 1px solid #39393928;
  border-bottom: 1px solid #39393928;
  line-height: 1.5;
  padding: 30px 0;
  font-size: 16px;
  margin: 20px 0;

  /* li:before {
    content: "- ";
  } */
`;

export const StyledContent = styled.div`
  position: relative;
  display: block;

  @media only screen and (min-width: 991px) {
    display: none;
  }
`;

export const StyledDivPageWrapper = styled.div`
  font-family: "Quicksand", sans-serif;

  @media only screen and (min-width: 991px) {
    padding: 0;
    margin-left: 250px;
    min-width: calc(100% - 300px);
    min-height: calc(100vh - 80px);
    flex: 1;
    border-left: 1px solid #39393928;
    max-width: 100vw;
  }
  max-width: 97vw;
  line-height: 1.5;

  /* h3 {
    padding: 0 !important;
    margin-bottom: 0 !important;
  } */
`;

// export const SidebarWrapper = styled.div`
//   /* display: none; */
//   @media only screen and (min-width: 991px) {
//     margin: 0;
//     display: block;
//     padding-top: 60px;
//     flex: 1;
//   }
// `;
// export const StyledSidebar = styled.div`
//   /* overflow: unset; */
//   /* display: none; */
//   /* box-sizing: unset; */
//   @media only screen and (min-width: 991px) {
//     display: block;
//     margin: 0;
//     position: sticky;
//     float: right;
//     top: 50px;
//     margin-bottom: 5px;
//     /* max-height: calc(100vh - 80px); */
//   }
// `;

export const CodeBlockWrapper = styled.div`
  margin: 5px 30px 5px 30px;
`;
