import styled from "styled-components";
import profilePic from "../../assets/images/profil.jpeg";
import { useEffect, useState } from "react";
import SocialMediaButtons from "../SocialMediaButtons/SocialMediaButtons";

// Styled Components
const AuthorAndDetailsContainer = styled.div`
  display: flex;
  align-items: center; /* Align items in the center */
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid #39393928;
`;

const FullWrapper = styled.div`
  border-bottom: 1px solid #39393928;
`;

const ProfilePictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 70px;
  height: 70px;
  object-fit: cover;
  box-shadow: 0px 0px 2px 0 black;
`;

const Name = styled.div`
  font-weight: thin;
  margin-top: 5px;
  text-align: center;
  /* text-shadow: 0px 0px 1px black; */
`;

const ArticleDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DateAndComponents = styled.div`
  justify-content: center;
  justify-items: center;
  justify-self: center;
`;

const AuthorAndDetails = ({
  date,
  components,
  languages,
  title,
  shareUrl
}: {
  date: string;
  components: string[];
  languages: string[];
  title: string;
  shareUrl: string;
}) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const shareButtonInSidebar = width > 1600;
  return (
    <FullWrapper>
      <AuthorAndDetailsContainer>
        <ProfilePictureContainer style={isMobile ? { margin: "0 20px" } : { margin: " 0 60px 0 30px" }}>
          <ProfilePicture src={profilePic} alt="Profile" />
          <Name></Name>
        </ProfilePictureContainer>
        <ArticleDetails>
          <DateAndComponents>
            {date.length > 0 ? (
              <>
                <strong>Date:</strong> <>{date}</>
              </>
            ) : (
              <></>
            )}{" "}
            <br />
            {components.length > 0 ? (
              <>
                <strong>Components:</strong> <>{components.join(", ")}</>
              </>
            ) : (
              <></>
            )}
            <br />
            {languages.length > 0 ? (
              <>
                <strong>Languages:</strong> <>{languages.join(", ")}</>
              </>
            ) : (
              <></>
            )}
            {!shareButtonInSidebar && <br />}
            <SocialMediaButtons shareUrl={shareUrl} title={title} />
          </DateAndComponents>
        </ArticleDetails>
      </AuthorAndDetailsContainer>
    </FullWrapper>
  );
};

export default AuthorAndDetails;
