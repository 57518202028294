import { useState, useRef, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

const CookieConsentWrapper = styled.div`
  font-family: "Quicksand", sans-serif;
  letter-spacing: 0.5px;
  position: fixed;
  padding: 20px;
  color: white;
  bottom: 0;
  width: 100vw;
  background-color: #212529;
  z-index: 100;
  box-shadow: 0 0 5px black;
`;

const COOKIE_CONSENT_LOCAL_STORAGE_KEY = "cookieConsent";
export const TRACKING_ID = "G-41KDFV1V4P"; // Measurement ID

export default function CookieConsent() {
  // eslint-disable-next-line
  let [cookieConsent, setCookieConsent] = useState<string[]>([]);
  const [show, setShow] = useState<boolean>(true);

  const cookieConsentPerformance = useRef<null | HTMLInputElement>(null);
  const cookieConsentFunctional = useRef<null | HTMLInputElement>(null);
  const cookieConsentAdvertising = useRef<null | HTMLInputElement>(null);

  function toggleSaveSelection() {
    cookieConsent = ["necessary"];
    // eslint-disable-next-line
    let performance = cookieConsentPerformance.current ? cookieConsentPerformance.current.checked : null;
    // eslint-disable-next-line
    let functional = cookieConsentFunctional.current?.checked;
    // eslint-disable-next-line
    let advertising = cookieConsentAdvertising.current?.checked;
    if (performance === true) {
      cookieConsent.push("performance");
    }
    if (functional === true) {
      cookieConsent.push("functional");
    }
    if (advertising === true) {
      cookieConsent.push("advertising");
      console.log("Info: Google Analytics activated");
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
    }

    setCookieConsent(() => {
      return cookieConsent;
    });
  }

  function toggleAcceptAll() {
    setCookieConsent(() => {
      return ["necessary", "performance", "functional", "advertising"];
    });
    console.log("Info: GA activated");
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
  }

  useEffect(() => {
    if (cookieConsent.length > 0) {
      localStorage.setItem(COOKIE_CONSENT_LOCAL_STORAGE_KEY, JSON.stringify(cookieConsent));
    }
  }, [cookieConsent]);

  useEffect(() => {
    const stored = localStorage.getItem(COOKIE_CONSENT_LOCAL_STORAGE_KEY);
    if (stored && stored.length > 2) {
      // length here is character based, an empty array like this "[]" is two characters.
      setShow(false);
    }
  }, [cookieConsent]);

  return show ? (
    <CookieConsentWrapper>
      <Container>
        <b>
          <h3>I use cookies</h3>
        </b>
        <p>
          I may use cookies for analysis of visitor data and to improve my website. Overall this data is used to provide
          a better website experience.
          <br /> For more information please see the{" "}
          <Link style={{ color: "white" }} to="/cookies">
            Cookies page
          </Link>
          .
        </p>

        <Form>
          <table>
            <tbody>
              <tr>
                <td style={{ padding: "5px" }}>
                  <Form.Check type="switch" id="necessary" label="Necessary" disabled checked />
                </td>
                <td style={{ padding: "5px" }}>
                  <Form.Check ref={cookieConsentPerformance} type="switch" id="performance" label="Performance" />
                </td>
              </tr>
              <tr>
                <td style={{ padding: "5px" }}>
                  <Form.Check ref={cookieConsentFunctional} type="switch" id="functional" label="Functional" />
                </td>
                <td style={{ padding: "5px" }}>
                  <Form.Check ref={cookieConsentAdvertising} type="switch" id="advertising" label="Advertising" />
                </td>
              </tr>
              <tr>
                <td style={{ padding: "20px 10px 5px 10px" }}>
                  <Button onClick={() => toggleSaveSelection()} variant="outline-secondary">
                    Save selection
                  </Button>
                </td>
                <td style={{ padding: "20px 10px 5px 10px" }}>
                  <Button onClick={() => toggleAcceptAll()} variant="outline-success">
                    Accept all
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      </Container>
    </CookieConsentWrapper>
  ) : null;
}
