import Nav from "react-bootstrap/Nav";
import { CloseButton, Container, Navbar as NavbarBS, Row } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconPrefix, IconName } from "@fortawesome/fontawesome-common-types";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button, { ButtonProps } from "react-bootstrap/Button";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ResultList from "../ResultList/ResultList";
import { githubIcon, ninjaIcon } from "../../styles/page-styles";
import { Pages } from "../../components/pages/pages";

const StyledForm = styled(Form)`
  width: 280px;

  .form-control:focus {
    /* overwrite input glow on focus */
    box-shadow: 0 0 0 0 #000000;
  }

  @media only screen and (max-width: 991px) {
    display: none !important;
  }

  .searchbar {
    color: white;
    background-color: #212529;
    border: 1px solid #6c757d;
    border-radius: 0;
  }
`;

const StyledNavDropdown = styled(NavDropdown)`
  letter-spacing: 1px;
  font-weight: 100;

  /* border: 4px solid red; */

  a.nav-link:not(.dropdown-toggle) {
    padding: 0 !important;
  }

  a.dropdown-toggle {
    padding: 7px;
    margin: 0 10px 0 10px;
  }

  /* font-size: 100%; */

  a {
    color: #858585;
    transition: color ease-out 0.333s;
  }
  a:hover {
    color: white;
    transition: color ease-out 0.333s;
  }

  .dropdown-menu {
    border-radius: 0;

    /* border: 4px solid red; */
    background-color: #212529;
    padding: 0 0 0px 0;
    left: -2px;
    margin-top: -2px;

    @media only screen and (max-width: 991px) {
      left: 0;
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 991px) {
    text-align: left;
    min-width: calc(100vw - 42px);
    max-width: calc(100vw - 30px);

    /* text-overflow: ellipsis; */

    a.dropdown-toggle {
      margin-left: 10px;
      padding: 8px;
    }
  }
  /* &:visited {
    background-color: #fff;
  } */
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 100;

  color: #858585;
  text-align: left;
  display: inline-block;
  letter-spacing: 1px;
  /* box-sizing: unset; */
  width: 100%;
  /* @media only screen and (max-width: 991px) {
    min-width: calc(100vw - 42px);
    max-width: calc(100vw - 30px);
  } */

  /* @media only screen and (max-width: 991px) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  } */

  .dropdown-item-icon {
    width: 35px;
    padding-right: 10px;
    @media only screen and (max-width: 991px) {
      width: auto;
      min-width: 25px;
      text-align: left;
      padding-right: 10px;
    }
  }

  .row {
    padding: 7px 10px 7px 10px;
    @media only screen and (max-width: 991px) {
      padding: 2px 10px;
    }
    margin: 0;
    transition: background-color ease-out 0.333s;
    background-color: #212529;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .row:hover {
    transition: background-color ease-out 0.333s;
    background-color: #343d47;
    color: white;
  }

  @media only screen and (min-width: 991px) {
    .row {
      padding-right: 10px; /* Adjust this value as needed */
    }
  }
`;

const StyledBrand = styled.span`
  @media only screen and (min-width: 991px) {
    margin-right: 20px;
    @keyframes border_anim {
      0% {
        width: 0%;
      }
      100% {
        width: 90%;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    @keyframes border_anim {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }
  }
  /* font-size: 1.25rem; */
  text-shadow: 0px 0px 5px black;

  a {
    color: white;
  }

  a:hover {
    background: linear-gradient(
      0deg,
      #65a8db 0%,
      #65a8db 2%,
      #65a8db37 3%,
      #65a8db13 20%,
      rgba(0, 0, 0, 0) 33%,
      rgba(0, 0, 0, 0) 100%
    );
    color: white;
    animation: border_anim 1s ease forwards;
  }
  a:hover::after {
    background: linear-gradient(0deg, red 0%, red 2%, rgba(0, 0, 0, 0) 2%, rgba(0, 0, 0, 0) 100%);
  }
`;

const StyledButton = styled(Button)<ButtonProps>`
  padding: 0;
  border-radius: 0;

  @media only screen and (max-width: 991px) {
    &:hover {
      background-color: #212529 !important;
    }
  }
`;

const searchIcon = <FontAwesomeIcon icon={["fa" as IconPrefix, "magnifying-glass" as IconName]} />;

const StyledNavbarBS = styled(NavbarBS)`
  z-index: 99999;
  box-shadow: 0px 0px 5px black;
  padding: 15px 20px;
  font-family: "Quicksand", sans-serif;

  @media only screen and (max-width: 991px) {
    #cloud-ninja-nav {
      margin-top: 30px;
    }
  }
`;

const StyledDivSearchRes = styled.div`
  position: absolute;
  overflow: hidden;
  width: 280px;
  background-color: #212529;
  margin-top: 18px;
  color: white;
  border: 1px solid #6c757d;

  @media only screen and (max-width: 991px) {
    display: none !important;
  }
`;

const GithubButton = styled(Button)<ButtonProps>`
  @media only screen and (max-width: 991px) {
    margin-top: 30px !important;
    &:after {
      content: " Github";
    }
  }
`;
const NavbarContainer = styled(Container).attrs({ fluid: true })`
  width: 100vw;
  padding: 0 0px;
  @media only screen and (min-width: 991px) {
    padding: 0 30px;
  }
  @media only screen and (min-width: 1600px) {
    width: 90vw !important;
    padding: 0 50px;
  }
  @media only screen and (min-width: 1800px) {
    width: 75vw !important;
  }
  @media only screen and (min-width: 2000px) {
    width: 70vw !important;
  }
  @media only screen and (min-width: 2200px) {
    width: 65vw !important;
  }
  @media only screen and (min-width: 2400px) {
    width: 60vw !important;
  }
  @media only screen and (min-width: 2600px) {
    width: 55vw !important;
  }
  @media only screen and (min-width: 2800px) {
    width: 50vw !important;
  }
  @media only screen and (min-width: 3200px) {
    width: 45vw !important;
  }
  @media only screen and (min-width: 3600px) {
    width: 40vw !important;
  }
  @media only screen and (min-width: 4000px) {
    width: 35vw !important;
  }
`;

export default function Navbar() {
  const [inputText, setInputText] = useState("");
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const dropdown1Ref = React.useRef() as React.RefObject<HTMLDivElement>;
  const dropdown2Ref = React.useRef() as React.RefObject<HTMLDivElement>;

  const searchBarRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const searchBarResRef = React.useRef() as React.RefObject<HTMLDivElement>;
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  function searchSite(event: React.FormEvent<HTMLFormElement> | null, eventType: string) {
    if (event !== null) {
      event.preventDefault();
    }
    const searchString = searchBarRef.current.value;
    setInputText(searchString);
    if (searchBarResRef.current && searchBarRef.current.value !== "") {
      searchBarResRef.current.style.display = "block";
    }

    if (searchBarRef.current.value === "") {
      if (searchBarResRef.current) {
        searchBarResRef.current.style.display = "none";
      }
    }

    if (eventType === "onSubmit") {
      searchBarRef.current.value = "";
      setInputText("");
      if (searchBarResRef.current) {
        searchBarResRef.current.style.display = "none";
      }
    }

    if (eventType === "onClickClose") {
      searchBarRef.current.value = "";
      setInputText("");
      if (searchBarResRef.current) {
        searchBarResRef.current.style.display = "none";
      }
    }
  }

  const goToTopRef = useRef<null | HTMLDivElement>(null);

  return (
    <>
      <div ref={goToTopRef}></div>
      <StyledNavbarBS collapseOnSelect={true} expand="lg" expanded={expanded} fixed="top" bg="dark" variant="dark">
        <NavbarContainer>
          <NavbarBS.Brand>
            <StyledBrand>
              <StyledLink to="/">
                <span className="ninja-icon">{ninjaIcon}</span> <span className="ninja-io-label">code-ninja.io</span>
              </StyledLink>
            </StyledBrand>
          </NavbarBS.Brand>
          <NavbarBS.Toggle onClick={() => setExpanded(!expanded)} aria-controls="responsive-navbar-nav" />
          <NavbarBS.Collapse id="responsive-navbar-nav">
            <Nav id="cloud-ninja-nav" className="me-auto">
              <StyledButton variant="dark">
                <StyledNavDropdown
                  onMouseOver={() => setShowDropdown1(true)}
                  onMouseOut={() => setShowDropdown1(false)}
                  show={showDropdown1}
                  menuVariant="dark"
                  title="Labs"
                  rootCloseEvent="mousedown"
                  renderMenuOnMount
                  ref={dropdown1Ref}
                >
                  {Pages.filter((page) => page.type === "Lab")
                    .sort((a, b) => a.headline.localeCompare(b.headline))
                    .map((page, index) => (
                      <StyledLink
                        key={`lab${index}`}
                        to={page.url}
                        onClick={() => {
                          setShowDropdown1(!showDropdown1);
                          if (isMobile) setExpanded(!expanded);
                          if (goToTopRef.current) {
                            goToTopRef.current.scrollIntoView({
                              behavior: "instant",
                              block: "end"
                            });
                          }
                        }}
                      >
                        <Row>
                          {page.pageIcon}
                          {page.headline}
                        </Row>
                      </StyledLink>
                    ))}
                </StyledNavDropdown>
              </StyledButton>
              <StyledButton variant="dark">
                <StyledNavDropdown
                  onMouseOver={() => setShowDropdown2(true)}
                  onMouseOut={() => setShowDropdown2(false)}
                  show={showDropdown2}
                  menuVariant="dark"
                  title="Documentation"
                  rootCloseEvent="mousedown"
                  renderMenuOnMount
                  ref={dropdown2Ref}
                >
                  {Pages.filter((page) => page.type === "Documentation")
                    .sort((a, b) => a.headline.localeCompare(b.headline))
                    .map((page, index) => (
                      <StyledLink
                        key={`documentation${index}`}
                        to={page.url}
                        onClick={() => {
                          setShowDropdown2(!showDropdown2);
                          if (isMobile) setExpanded(!expanded);
                          if (goToTopRef.current) {
                            goToTopRef.current.scrollIntoView({
                              behavior: "instant",
                              block: "end"
                            });
                          }
                        }}
                      >
                        <Row>
                          {page.pageIcon}
                          {page.headline}
                        </Row>
                      </StyledLink>
                    ))}
                </StyledNavDropdown>
              </StyledButton>
            </Nav>
            <div>
              <StyledDivSearchRes style={{ display: "none" }} ref={searchBarResRef} id="searchbar-results">
                <div
                  onClick={() => searchSite(null, "onClickClose")}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    borderRadius: "0",
                    border: "none",
                    color: "white"
                  }}
                >
                  <CloseButton variant="white" />
                </div>
                <ResultList inputRef={searchBarRef} searchResultsRef={searchBarResRef} input={inputText}></ResultList>
              </StyledDivSearchRes>
            </div>
            <StyledForm
              className="d-flex me-5"
              onChange={(event: React.FormEvent<HTMLFormElement>) => searchSite(event, "onChange")}
              onSubmit={(event: React.FormEvent<HTMLFormElement>) => searchSite(event, "onSubmit")}
            >
              <InputGroup>
                <Form.Control
                  type="search"
                  ref={searchBarRef}
                  className="searchbar"
                  placeholder="search.."
                  autoComplete="off"
                  id="searchbar-input"
                />
                <Button style={{ borderRadius: 0 }} variant="outline-secondary" type="submit">
                  {searchIcon}
                </Button>
              </InputGroup>
            </StyledForm>

            <Nav>
              <Nav.Link active style={{ color: "#858585" }} href="https://github.com/lukasbuerkle" target="_blank">
                <GithubButton style={{ borderRadius: 0, margin: 0, width: "100%" }} variant="outline-secondary">
                  {githubIcon}
                </GithubButton>
              </Nav.Link>
            </Nav>
          </NavbarBS.Collapse>
        </NavbarContainer>
      </StyledNavbarBS>
    </>
  );
}
