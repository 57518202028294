import React, { MutableRefObject, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { StyledTD, listIcon } from "../../styles/page-styles";
import styled from "styled-components";

interface MenuEntry {
  headline: string;
  description: string;
  ref: MutableRefObject<HTMLDivElement | null>;
}

interface SidebarMenuProps {
  menu: MenuEntry[];
  goTo: (ref: MutableRefObject<HTMLDivElement | null>) => void;
}

const SidebarWrapper = styled.div`
  position: fixed;
  top: 80px;
  width: 250px;
  height: 100vh;
  overflow: hidden;

  @media only screen and (max-width: 990px) {
    display: none;
  }
`;

const StyledSidebar = styled.div<{ $height: number }>`
  height: ${(props) => props.$height}px;
  background-color: rgba(0, 0, 0, 0);
  overflow-y: auto;
  margin-top: 5px;
  padding: 20px 0px 0px 0px;
  scrollbar-width: thin;
`;

const SidebarMenu: React.FC<SidebarMenuProps> = ({ menu, goTo }) => {
  const [height, setHeight] = useState(window.innerHeight - 80);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  useEffect(() => {
    const footer = document.getElementById("footer");

    const handleScroll = () => {
      if (footer) {
        const result = isElementInViewport(footer);
        const newHeight = result.isInViewport
          ? window.innerHeight - 80 - result.visiblePixels
          : window.innerHeight - 80;
        setHeight(newHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Call it once initially in case the user is already scrolled down

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px 0px -90% 0px", // This centers the element in the viewport
      threshold: 0 // Trigger when the top of the element hits the middle
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = menu.findIndex((menuEntry) => menuEntry.ref.current === entry.target);
          setActiveIndex(index);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    menu.forEach((menuEntry) => {
      if (menuEntry.ref.current) {
        observer.observe(menuEntry.ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [menu]);

  return (
    <SidebarWrapper>
      <StyledSidebar $height={height}>
        <h5 style={{ fontWeight: "600", paddingLeft: "5px" }}>List of Contents</h5>
        <Table
          hover
          size="xxl"
          style={{
            borderBottom: "1px solid #39393928",
            backgroundColor: "rgba(0,0,0,0)"
          }}
        >
          <tbody style={{ backgroundColor: "rgba(0,0,0,0)" }}>
            {menu.map((menuEntry, index) => (
              <tr
                style={{
                  backgroundColor: activeIndex === index ? "#f0f0f0" : "rgba(0,0,0,0)"
                }}
                key={index}
              >
                <StyledTD onClick={() => goTo(menuEntry.ref)}>
                  <div style={{ backgroundColor: "rgba(0,0,0,0)" }} className="content-icon">
                    {listIcon}
                  </div>
                  <div className="content-item" style={{ marginLeft: "15px", backgroundColor: "rgba(0,0,0,0)" }}>
                    {index + 1}. {menuEntry.headline}
                    <br />
                    <div className="text-muted" style={{ fontSize: "14px" }}>
                      {menuEntry.description}
                    </div>
                  </div>
                </StyledTD>
              </tr>
            ))}
          </tbody>
        </Table>
      </StyledSidebar>
    </SidebarWrapper>
  );
};

export default SidebarMenu;

function isElementInViewport(el: HTMLElement): { isInViewport: boolean; visiblePixels: number } {
  const rect = el.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  const isInViewport = rect.top <= windowHeight && rect.bottom >= 0;

  let visiblePixels = 0;
  if (rect.top >= 0 && rect.bottom <= windowHeight) {
    visiblePixels = rect.height;
  } else if (rect.top < 0 && rect.bottom > 0) {
    visiblePixels = rect.bottom;
  } else if (rect.top >= 0 && rect.bottom > windowHeight) {
    visiblePixels = windowHeight - rect.top;
  } else if (rect.top < 0 && rect.bottom > windowHeight) {
    visiblePixels = windowHeight;
  }

  return {
    isInViewport: isInViewport,
    visiblePixels: visiblePixels
  };
}
