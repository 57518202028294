import { awsIcon, bookIcon, goIcon, nodeIcon, pythonIcon, reactIcon } from "../../styles/page-styles";

type Label = {
  text: string;
  link: string;
};

type Page = {
  type: string;
  headline: string;
  url: string;
  date: string;
  description: string;
  labels: Label[];
  pageIcon: JSX.Element;
  searchValues: string[];
};

export const Pages: Page[] = [
  {
    type: "Documentation",
    headline: "cURL Guide",
    url: "/docs/curl-guide",
    date: "Feb 14, 2023",
    description: "A guide on using cURL for various HTTP methods, handling responses and advanced features.",
    labels: [{ text: "CLI", link: "#" }],
    pageIcon: bookIcon,
    searchValues: ["documentation"]
  },
  {
    type: "Lab",
    headline: "CI/CD Website deployment on AWS with Pulumi & Github",
    url: "/aws/labs/ci-cd-website-deployment",
    date: "Feb 11, 2023",
    description:
      "A step-by-step guide to deploying a CI/CD pipeline on AWS for a website, using Pulumi, Github, AWS CodePipeline, AWS CodeBuild and Amazon CloudFront.",
    labels: [
      { text: "AWS", link: "#" },
      { text: "CI/CD", link: "#" },
      { text: "CodePipeline", link: "#" },
      { text: "S3", link: "#" },
      { text: "CodeBuild", link: "#" },
      { text: "Cloudfront", link: "#" }
    ],
    pageIcon: awsIcon,
    searchValues: ["AWS", "CodePipeline", "CodeBuild", "Cloudfront", "CDN", "lab", "Amazon"]
  },
  {
    type: "Documentation",
    headline: "GO Guide",
    url: "/docs/go-guide",
    date: "Mar 28, 2024",
    description:
      "A detailed guide to learning Go, from installation and setup to advanced concurrency and object-oriented constructs.",
    labels: [
      { text: "GO", link: "#" },
      { text: "Golang", link: "#" },
      { text: "Programming", link: "#" }
    ],
    pageIcon: goIcon,
    searchValues: ["documentation"]
  },
  {
    type: "Documentation",
    headline: "Typescript Guide",
    url: "/docs/typescript-guide",
    date: "Jun 20, 2024",
    description: "A guide to setting up and using TypeScript for building scalable and maintainable applications.",
    labels: [
      { text: "Typescript", link: "#" },
      { text: "Programming", link: "#" }
    ],
    pageIcon: nodeIcon,
    searchValues: ["documentation"]
  },
  {
    type: "Documentation",
    headline: "Python Guide",
    url: "/docs/python-guide",
    date: "Jun 02, 2024",
    description:
      "An in-depth guide to mastering Python, covering essential concepts, advanced features and best practices for effective programming.",
    labels: [
      { text: "Python", link: "#" },
      { text: "Programming", link: "#" }
    ],
    pageIcon: pythonIcon,
    searchValues: ["documentation"]
  },
  {
    type: "Documentation",
    headline: "React Guide",
    url: "/docs/react-guide",
    date: "May 12, 2024",
    description:
      "This guide to React covers fundamental concepts, advanced topics and practical examples, providing a structured path to mastering React for building dynamic and efficient web applications.",
    labels: [
      { text: "React", link: "#" },
      { text: "Programming", link: "#" }
    ],
    pageIcon: reactIcon,
    searchValues: ["documentation"]
  },
  {
    type: "Documentation",
    headline: "React to NextJS Migration Guide",
    url: "/docs/react-to-nextjs-guide",
    date: "May 12, 2024",
    description:
      "This guide to React covers fundamental concepts, advanced topics and practical examples, providing a structured path to mastering React for building dynamic and efficient web applications.",
    labels: [
      { text: "React", link: "#" },
      { text: "Programming", link: "#" },
      { text: "Next.js", link: "#" }
    ],
    pageIcon: reactIcon,
    searchValues: ["documentation"]
  },
  {
    type: "Lab",
    headline: "Pokedex App with React and TypeScript",
    url: "/lab/react-and-typescript",
    date: "Mar 02, 2024",
    description:
      "A step-by-step guide to building a Pokedex App with React and TypeScript, covering project setup, data fetching, component creation and navigation implementation.",
    labels: [
      { text: "React", link: "#" },
      { text: "Typescript", link: "#" },
      { text: "Pokedex", link: "#" }
    ],
    pageIcon: reactIcon,
    searchValues: ["lab"]
  },
  {
    type: "Lab",
    headline: "Getting Started with Infrastructure as Code (IaC) using AWS CDK",
    url: "/lab/aws-cdk-first-steps",
    date: "Jun 20, 2024",
    description:
      "This guide covers everything you need to get started with Infrastructure as Code (IaC) using AWS CDK.",
    labels: [
      { text: "Typescript", link: "#" },
      { text: "JavaScript", link: "#" },
      { text: "AWS CDK", link: "#" }
    ],
    pageIcon: awsIcon,
    searchValues: ["lab", "AWS CDK"]
  },
  {
    type: "Documentation",
    headline: "API Types",
    url: "/docs/api-types",
    date: "Oct 08, 2024",
    description:
      "This guide provides a detailed overview of six common API types: REST, SOAP, GraphQL, gRPC, JSON-RPC and Falcor.",
    labels: [
      { text: "REST", link: "#" },
      { text: "SOAP", link: "#" },
      { text: "GraphQL", link: "#" },
      { text: "gRPC", link: "#" },
      { text: "JSON-RPC", link: "#" },
      { text: "Falcor", link: "#" }
    ],
    pageIcon: bookIcon,
    searchValues: ["lab", "API", "Types", "REST", "SOAP", "GraphQL", "gRPC", "JSON-RPC", "Falcor"]
  }
];
