import { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import {
  StyledDescription,
  StyledDivPageWrapper,
  StyledDivText,
  StyledH1,
  StyledPreconditions
} from "../../styles/page-styles";
import SidebarMenu from "../../components/SidebarMenu/SidebarMenu";
import AuthorAndDetails from "../../components/AuthorAndDetails/AuthorAndDetails";
import { ReactNodeToString } from "../../components/SocialMediaButtons/SocialMediaButtons";
import { goTo } from "../../components/GoToFunc/GoToFunc";

export default function ApiGuide() {
  const goTo1 = useRef<null | HTMLDivElement>(null);
  const goTo2 = useRef<null | HTMLDivElement>(null);
  const goTo3 = useRef<null | HTMLDivElement>(null);
  const goTo4 = useRef<null | HTMLDivElement>(null);
  const goTo5 = useRef<null | HTMLDivElement>(null);
  const goTo6 = useRef<null | HTMLDivElement>(null);
  const goTo7 = useRef<null | HTMLDivElement>(null);

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  const menu = [
    {
      headline: "Introduction",
      description: "Overview of API Types",
      ref: goTo1
    },
    {
      headline: "REST",
      description: "Introduction to REST",
      ref: goTo2
    },
    {
      headline: "SOAP",
      description: "Introduction to SOAP",
      ref: goTo3
    },
    {
      headline: "GraphQL",
      description: "Introduction to GraphQL",
      ref: goTo4
    },
    {
      headline: "gRPC",
      description: "Introduction to gRPC",
      ref: goTo5
    },
    {
      headline: "JSON-RPC & Falcor",
      description: "Introduction to JSON-RPC & Falcor",
      ref: goTo6
    },
    {
      headline: "Comparison & Conclusion",
      description: "Summary and Table Comparison",
      ref: goTo7
    }
  ];

  const date = "06. Oct. 2024, 2:30 PM";
  const components = ["REST", "SOAP", "GraphQL", "gRPC", "JSON-RPC", "Falcor"];
  const languages: string[] = [];

  const shareUrl = window.location.href;
  const title = "Overview of API Types";

  return (
    <div style={{ display: "flex" }}>
      <SidebarMenu menu={menu} goTo={goTo} />
      <StyledDivPageWrapper>
        <StyledH1>{title}</StyledH1>

        <AuthorAndDetails
          shareUrl={shareUrl}
          title={ReactNodeToString(title)}
          date={date}
          components={components}
          languages={languages}
        />
        <h3
          style={{
            fontWeight: "600",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "20px"
          }}
        >
          Description
        </h3>
        <StyledDescription>
          This guide explores six key API types: REST, SOAP, GraphQL, gRPC, JSON-RPC and Falcor. Each section details
          practical use cases, key benefits and potential drawbacks, helping developers select the most appropriate
          architecture for their projects.
        </StyledDescription>

        <StyledPreconditions
          style={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "20px"
          }}
        >
          <h3>Preconditions</h3>
          Before diving into the details of each API type, you should:
          <div className="mt-2">
            <li>Have a basic understanding of programming and API structures.</li>
            <li>Understand HTTP and networking fundamentals.</li>
            <li>Be familiar with modern web technologies and application needs.</li>
          </div>
        </StyledPreconditions>

        <div ref={goTo1}></div>
        <h3 style={{ fontWeight: "600", margin: "50px 30px 30px 30px" }}>1. Introduction</h3>
        <StyledDivText>
          APIs (Application Programming Interfaces) are essential for enabling communication between different software
          components. As applications become more distributed, the role of APIs becomes crucial in how systems share
          data and functionality. This guide covers six widely used API architectures: REST, SOAP, GraphQL, gRPC,
          JSON-RPC and Falcor. These technologies serve various purposes and are chosen based on the needs of the
          project, whether it&apos;s simplicity, security, performance or flexibility.
        </StyledDivText>

        <div ref={goTo2}></div>
        <h3 style={{ fontWeight: "600", margin: "50px 30px 30px 30px" }}>2. REST (Representational State Transfer)</h3>
        <StyledDivText>
          REST is a flexible, widely-used architectural style that leverages HTTP protocols for client-server
          communication. It is often used for creating simple, scalable APIs for web applications. REST APIs expose
          resources as URLs and clients interact with these resources using standard HTTP methods like `GET`, `POST`,
          `PUT`and `DELETE`.
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Practical Use Cases</h6>
        <StyledDivText>
          REST is ideal for applications where simplicity and scalability are key. It&apos;s widely used in web and
          mobile apps for tasks like user management, content delivery and interactions with third-party services (e.g.,
          payment gateways or social media APIs). Because of its stateless nature, REST APIs can scale horizontally,
          making them a good fit for cloud-based applications.
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Advantages</h6>
        <StyledDivText>
          <ul>
            <li>Easy to implement and understand due to HTTP-based interaction.</li>
            <li>Supports multiple formats (JSON, XML, etc.), allowing flexibility in data exchange.</li>
            <li>Highly scalable because each request is independent (stateless).</li>
          </ul>
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Disadvantages</h6>
        <StyledDivText>
          <ul>
            <li>Can lead to over-fetching or under-fetching of data if the API is not designed properly.</li>
            <li>Limited in transactional capabilities and lacks built-in security features compared to SOAP.</li>
          </ul>
        </StyledDivText>

        <div ref={goTo3}></div>
        <h3 style={{ fontWeight: "600", margin: "50px 30px 30px 30px" }}>3. SOAP (Simple Object Access Protocol)</h3>
        <StyledDivText>
          SOAP is a protocol for exchanging structured information using XML. It&apos;s designed for secure, reliable
          transactions, making it a strong candidate for enterprise applications that require robust security measures
          and complex workflows.
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Practical Use Cases</h6>
        <StyledDivText>
          SOAP is often used in industries like finance, healthcare and government, where security and compliance are
          crucial. For example, bank-to-bank transactions often use SOAP due to its built-in support for WS-Security,
          ensuring encrypted, authenticated and tamper-proof communications. SOAP is also a popular choice for services
          where atomic transactions are required.
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Advantages</h6>
        <StyledDivText>
          <ul>
            <li>Comprehensive security features with WS-Security, ideal for sensitive data transactions.</li>
            <li>
              Supports ACID (Atomicity, Consistency, Isolation, Durability) transactions, making it reliable for complex
              workflows.
            </li>
            <li>Protocol-agnostic (can use HTTP, SMTP, TCP, etc.).</li>
          </ul>
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Disadvantages</h6>
        <StyledDivText>
          <ul>
            <li>Heavyweight and slower due to the XML format, leading to increased bandwidth usage.</li>
            <li>More complex to implement and harder to maintain compared to REST.</li>
          </ul>
        </StyledDivText>

        <div ref={goTo4}></div>
        <h3 style={{ fontWeight: "600", margin: "50px 30px 30px 30px" }}>4. GraphQL</h3>
        <StyledDivText>
          GraphQL is a query language for APIs that allows clients to specify exactly what data they need, minimizing
          data over-fetching and under-fetching. This flexibility makes GraphQL a powerful tool for optimizing the
          performance of modern applications, especially in the context of front-end and mobile development.
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Practical Use Cases</h6>
        <StyledDivText>
          GraphQL is widely adopted in scenarios where front-end developers need control over the data they request.
          It’s particularly valuable for single-page applications (SPAs) or mobile apps with limited bandwidth. For
          example, a social media app that needs to retrieve a user’s profile, friendsand posts can request exactly the
          necessary fields in one query, avoiding multiple round trips to the server.
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Advantages</h6>
        <StyledDivText>
          <ul>
            <li>Flexible and efficient, allowing clients to request exactly the data they need.</li>
            <li>Reduces over-fetching, making it ideal for applications with limited bandwidth, like mobile apps.</li>
            <li>Single endpoint for all queries, simplifying API design.</li>
          </ul>
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Disadvantages</h6>
        <StyledDivText>
          <ul>
            <li>More complex to implement on the server-side compared to REST.</li>
            <li>Potential performance issues with deeply nested queries if not optimized properly.</li>
          </ul>
        </StyledDivText>

        <div ref={goTo5}></div>
        <h3 style={{ fontWeight: "600", margin: "50px 30px 30px 30px" }}>5. gRPC (gRPC Remote Procedure Calls)</h3>
        <StyledDivText>
          gRPC, developed by Google, is a high-performance, low-latency RPC (Remote Procedure Call) framework that uses
          HTTP/2 for transport and Protocol Buffers (Protobuf) for data serialization. It is particularly useful in
          microservices architectures and real-time communication systems.
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Practical Use Cases</h6>
        <StyledDivText>
          gRPC excels in scenarios where performance is critical, such as within microservices ecosystems where services
          communicate frequently. It is also widely used in real-time applications, such as video streaming services,
          where low latency and efficient data transmission are paramount. Its ability to support bi-directional
          streaming makes it a preferred choice for applications requiring real-time updates, like chat systems or live
          collaboration tools.
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Advantages</h6>
        <StyledDivText>
          <ul>
            <li>Efficient, using Protobuf for faster, smaller data transmission.</li>
            <li>Supports bidirectional streaming, ideal for real-time communication.</li>
            <li>Strongly-typed communication with robust code generation for multiple languages.</li>
          </ul>
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Disadvantages</h6>
        <StyledDivText>
          <ul>
            <li>Complex to set up, requiring knowledge of Protobuf and gRPC framework.</li>
            <li>
              Less human-readable than JSON-based APIs, making it harder for debugging and testing without specialized
              tools.
            </li>
          </ul>
        </StyledDivText>

        <div ref={goTo6}></div>
        <h3 style={{ fontWeight: "600", margin: "50px 30px 30px 30px" }}>6. JSON-RPC & Falcor</h3>
        <StyledDivText>
          JSON-RPC is a lightweight remote procedure call (RPC) protocol that uses JSON for data transmission, while
          Falcor is a data-fetching library developed by Netflix to optimize client-side data retrieval. Both
          technologies are geared towards lightweight, high-efficiency operations.
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Practical Use Cases</h6>
        <StyledDivText>
          JSON-RPC is well-suited for simple, lightweight services that require minimal complexity, making it a good
          option for internal services or tools that need to exchange data quickly without much overhead. Falcor, on the
          other hand, shines in applications that deal with large amounts of distributed data, such as media streaming
          or e-commerce platforms. By allowing clients to treat all data as a virtual graph, Falcor minimizes redundant
          data requests.
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Advantages</h6>
        <StyledDivText>
          <ul>
            <li>JSON-RPC is simple and lightweight, making it easy to implement and use.</li>
            <li>Falcor reduces redundant data-fetching, improving performance for data-heavy applications.</li>
          </ul>
        </StyledDivText>

        <h6 style={{ fontWeight: "600", margin: "30px 30px 20px 30px" }}>Disadvantages</h6>
        <StyledDivText>
          <ul>
            <li>JSON-RPC lacks the advanced security features of SOAP or the flexibility of GraphQL.</li>
            <li>
              Falcor is less widely adopted compared to REST or GraphQL, making it harder to find community support.
            </li>
          </ul>
        </StyledDivText>

        <div ref={goTo7}></div>
        <h3 style={{ fontWeight: "600", margin: "50px 30px 30px 30px" }}>Comparison & Conclusion</h3>

        <StyledDivText>
          After exploring each of the six API types, it is clear that each one has its own strengths and weaknesses,
          depending on the specific use case. REST remains the go-to choice for simple, scalable web services,
          particularly when interoperability across different systems is needed. SOAP shines in environments where
          security and transactional integrity are paramount, such as in banking and healthcare systems. GraphQL excels
          in scenarios where flexible data fetching and optimized bandwidth usage are critical, especially in frontend
          and mobile applications. For performance-intensive microservices or real-time applications, gRPC offers low
          latency and efficient communication with its use of Protocol Buffers. JSON-RPC is favored for lightweight,
          internal communication, while Falcor brings a unique approach to optimizing data-fetching for complex,
          distributed data models. Ultimately, the choice of API depends heavily on the requirements of the application,
          from security and complexity to performance and flexibility.
        </StyledDivText>
        <Table
          style={
            isMobile
              ? { fontSize: "12px", margin: "0 auto", alignSelf: "center" }
              : { width: "calc(100% - 50px)", margin: "0 auto" }
          }
          responsive
          striped
          bordered
          hover
        >
          {/* <Table style={{ width: "calc(100% - 50px)", margin: "0 auto" }} striped bordered hover> */}
          <thead>
            <tr>
              <th>API Type</th>
              <th>Use Cases</th>
              <th>Advantages</th>
              <th>Disadvantages</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>REST</td>
              <td>Web/mobile apps, cloud services</td>
              <td>Easy to implement, scalable, flexible</td>
              <td>Over/under-fetching, lacks built-in security</td>
            </tr>
            <tr>
              <td>SOAP</td>
              <td>Enterprise systems, secure transactions</td>
              <td>Comprehensive security, reliable, ACID support</td>
              <td>Heavyweight, complex, slower due to XML</td>
            </tr>
            <tr>
              <td>GraphQL</td>
              <td>SPAs, mobile apps, modern web apps</td>
              <td>Flexible queries, single endpoint, reduces over-fetching</td>
              <td>Complex implementation, potential performance issues</td>
            </tr>
            <tr>
              <td>gRPC</td>
              <td>Microservices, real-time systems</td>
              <td>Low-latency, bidirectional streaming, efficient data transmission</td>
              <td>Complex setup, less human-readable</td>
            </tr>
            <tr>
              <td>JSON-RPC</td>
              <td>Lightweight services, internal tools</td>
              <td>Simple, easy to implement</td>
              <td>Lacks advanced features and security</td>
            </tr>
            <tr>
              <td>Falcor</td>
              <td>Data-heavy apps, distributed systems</td>
              <td>Reduces redundant data-fetching</td>
              <td>Less widely adopted, smaller community support</td>
            </tr>
          </tbody>
        </Table>
        <br />
      </StyledDivPageWrapper>
    </div>
  );
}
