import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin, faGithub, faTelegram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { ReactNode, useState } from "react";
import React from "react";

export const ReactNodeToString = (node: ReactNode): string => {
  if (typeof node === "string") {
    return node;
  }
  if (React.isValidElement(node)) {
    const { children } = node.props as { children?: ReactNode };
    if (typeof children === "string") {
      return children;
    }
  }
  return "";
};

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1000;

  @media only screen and (min-width: 1600px) {
    position: fixed;
    top: 100px;
    right: 20px;
    gap: 10px;
  }

  @media only screen and (max-width: 1599px) {
    display: inline-block;
    padding: 15px 15px 15px 0px;
  }
`;

const SocialMediaButton = styled.a`
  font-size: 24px;
  width: 50px;
  height: 50px;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;

  @media only screen and (min-width: 1600px) {
    box-shadow: 0 0 7px 0px grey;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 1599px) {
    box-shadow: 0 0 3px 0px grey;
    margin-right: 5px;
    margin-top: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    box-shadow: 0 0 0px 0px grey;
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
    @media only screen and (min-width: 1600px) {
      right: calc(100% + 10px);
    }
    @media only screen and (max-width: 1599px) {
      left: 60px;
      position: absolute;
    }
  }

  span {
    @media only screen and (min-width: 1600px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 1599px) {
      font-size: 12px;
      display: none;
    }
    position: absolute;
    white-space: nowrap;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

    @media only screen and (min-width: 1600px) {
      left: auto;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    @media only screen and (max-width: 1599px) {
      left: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media only screen and (max-width: 1599px) {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  &.github {
    background: #333;
  }

  &.github:hover {
    background: #444;
  }

  &.github span {
    background-color: rgba(0, 0, 0, 0);
    color: #333;
  }

  &.linkedin {
    background: #0077b5;
  }

  &.linkedin:hover {
    background: #005983;
  }

  &.linkedin span {
    background-color: rgba(0, 0, 0, 0);
    color: #0077b5;
  }

  &.facebook {
    background: #3b5998;
  }

  &.facebook:hover {
    background: #2d4373;
  }

  &.facebook span {
    background-color: rgba(0, 0, 0, 0);
    color: #3b5998;
  }

  &.whatsapp {
    background: #25d366;
  }

  &.whatsapp:hover {
    background: #20b358;
  }

  &.whatsapp span {
    background-color: rgba(0, 0, 0, 0);
    color: #25d366;
  }

  &.telegram {
    background: #0088cc;
  }

  &.telegram:hover {
    background: #006699;
  }

  &.telegram span {
    background-color: rgba(0, 0, 0, 0);
    color: #0088cc;
  }

  &.copy {
    background: #555;
  }

  &.copy:hover {
    background: #666;
  }

  &.copy span {
    background-color: rgba(0, 0, 0, 0);
    color: #555;
  }
`;

interface SocialMediaButtonsProps {
  shareUrl: string;
  title: React.ReactNode; // Allow string or React element
}

const SocialMediaButtons: React.FC<SocialMediaButtonsProps> = ({ shareUrl, title }) => {
  const titleStr = ReactNodeToString(title);
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Hide message after 2 seconds
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  const handleCopyClick = () => {
    // eslint-disable-next-line
    copyToClipboard();
  };

  return (
    <SocialMediaContainer>
      <SocialMediaButton
        className="github"
        href={`https://github.com/login?return_to=${encodeURIComponent(shareUrl)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faGithub} />
        <span>Share on Github</span>
      </SocialMediaButton>
      <SocialMediaButton
        className="linkedin"
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin} />
        <span>Share on LinkedIn</span>
      </SocialMediaButton>
      <SocialMediaButton
        className="facebook"
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFacebook} />
        <span>Share on Facebook</span>
      </SocialMediaButton>
      <SocialMediaButton
        className="whatsapp"
        href={`https://api.whatsapp.com/send?text=${encodeURIComponent(titleStr + " " + shareUrl)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
        <span>Share on WhatsApp</span>
      </SocialMediaButton>
      <SocialMediaButton
        className="telegram"
        href={`https://telegram.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(titleStr)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faTelegram} />
        <span>Share on Telegram</span>
      </SocialMediaButton>
      <SocialMediaButton className="copy" onClick={handleCopyClick} style={{ cursor: "pointer" }}>
        <FontAwesomeIcon icon={faCopy} />
        <span>{copySuccess ? "Copied!" : "Copy Link"}</span>
      </SocialMediaButton>
    </SocialMediaContainer>
  );
};

export default SocialMediaButtons;
