import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { faHeart, faEnvelope, faCoffee, faCopyright, faLock, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { useRef } from "react";

const envelopeicon = <FontAwesomeIcon style={{ width: "20px" }} icon={faEnvelope} color="#858585" />;
const hearticon = <FontAwesomeIcon beat icon={faHeart} color="red" />;
const coffeeicon = <FontAwesomeIcon icon={faCoffee} color="#6888ab" />;
const githubIcon = <FontAwesomeIcon style={{ width: "20px" }} icon={faGithub} color="#858585" />;
const linkedInIcon = <FontAwesomeIcon style={{ width: "20px" }} icon={faLinkedin} color="#858585" />;
const copyrightIcon = <FontAwesomeIcon color="#858585" icon={faCopyright} />;
const lockIcon = <FontAwesomeIcon style={{ width: "20px" }} icon={faLock} color="#858585" />;
const paperclipIcon = <FontAwesomeIcon style={{ width: "20px" }} icon={faPaperclip} color="#858585" />;
// import { GoToTopRef } from "../Navbar/Navbar";

const FooterContainer = styled.div`
  font-family: "Quicksand", sans-serif;
  letter-spacing: 0.3px;
  margin: 0 auto;
  padding: 0 50px 0 50px;

  a {
    color: #858585;
  }
  a:hover {
    color: white;
  }
  a:focus {
    color: white;
  }

  color: #858585;
  &:hover {
    color: white;
  }

  @media only screen and (min-width: 1600px) {
    width: 90vw;
  }
  @media only screen and (min-width: 1800px) {
    width: 75vw;
  }
  @media only screen and (min-width: 2000px) {
    width: 70vw;
  }
  @media only screen and (min-width: 2200px) {
    width: 65vw;
  }
  @media only screen and (min-width: 2400px) {
    width: 60vw;
  }
  @media only screen and (min-width: 2600px) {
    width: 55vw;
  }
  @media only screen and (min-width: 2800px) {
    width: 50vw;
  }
  @media only screen and (min-width: 3200px) {
    width: 45vw;
  }
  @media only screen and (min-width: 3600px) {
    width: 40vw;
  }
  @media only screen and (min-width: 4000px) {
    width: 35vw;
  }
`;

export default function Footer() {
  const goToTopRef = useRef<null | HTMLDivElement>(null);
  <div style={{ position: "absolute", top: "0" }} ref={goToTopRef}></div>;
  return (
    <footer
      style={{
        backgroundColor: "#212529",
        boxShadow: "0 0 5px black",
        zIndex: "100000"
      }}
      id="footer"
      className="pt-3 footer"
    >
      <FooterContainer>
        <Row>
          <Col lg={3} xs={12}>
            <h4 style={{ color: "white" }}>Contact</h4>
            <p className="pr-5">
              {githubIcon}{" "}
              <a href="https://github.com/lukasbuerkle" target="_blank" rel="noreferrer">
                Github
              </a>
              <br />
              {linkedInIcon}{" "}
              <a href="https://www.linkedin.com/in/lukas-b%C3%BCrkle-31801b163/" target="_blank" rel="noreferrer">
                LinkedIn
              </a>
              <br />
              {envelopeicon} <a href="mailto:lukasbuerkle@gmx.de">Send a mail</a>
            </p>
          </Col>

          <Col lg={3} xs={12}>
            <h4 style={{ color: "white" }}>Featured categories</h4>
            <p style={{ color: "#858585" }} className="pr-3">
              <Link to="/cloud/faq">serverless</Link>
              <br />
              <Link to="/cloud/faq">cloud-engineering</Link>
              <br />
              <Link to="/cloud/faq">web-development</Link>
            </p>
          </Col>
          <Col lg={3} xs={12}>
            <h4 style={{ color: "white" }}>Legal</h4>
            <p style={{ color: "#858585" }}>
              {paperclipIcon}{" "}
              <Link
                to="/imprint"
                onClick={() => {
                  if (document.getElementById("root")) {
                    document.getElementById("root")?.scrollIntoView({
                      behavior: "instant",
                      block: "start"
                    });
                  }
                }}
              >
                Imprint
              </Link>
              <br />
              {lockIcon}{" "}
              <Link
                to="/privacy-policy"
                onClick={() => {
                  if (document.getElementById("root")) {
                    document.getElementById("root")?.scrollIntoView({
                      behavior: "instant",
                      block: "start"
                    });
                  }
                }}
              >
                Privacy Policy
              </Link>
              <br />
              {/* {paperclipIcon}{" "}
              <Link to="/terms-of-service">Terms of Service</Link>
              <br /> */}
            </p>
          </Col>
          <Col lg={3} xs={12}>
            <h4 style={{ color: "white" }}>Explore</h4>
            <p>
              <Link to="/cloud/faq">lambda</Link>
              <br />
              <Link to="/cloud/faq">react</Link>
              <br />
              <Link to="/cloud/faq">golang</Link>
            </p>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <p>
              <small style={{ color: "#858585" }}>
                coded with {hearticon} and {coffeeicon} - {copyrightIcon} Copyright {new Date().getFullYear()}
              </small>
            </p>
          </Col>
        </Row>
      </FooterContainer>
    </footer>
  );
}
